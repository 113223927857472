
.details_header {
    color: var(--special_color_dark);
}

.modal-header .focused {
    background-color: var(--special_color_dark) !important;
}

.accordion_header {
    cursor: pointer;
}