
.table td, .table th {
  cursor: pointer;
  max-width: 360px !important;
}

.sort_icons {
  height: 10px;
  margin-right: 4px;
}


