body {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

:root {
  --special_color: #222;
  --special_color_dark: #000;
}

.special_color {
  background-color: var(--special_color) !important;
}

.special_color_dark {
  background-color: var(--special_color_dark) !important;
}

nav .nav-link:hover {
  text-decoration: underline !important;
}

.navbar {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-bottom: 2px solid var(--special_color) !important;
}

.md-form label {
  color: #495057 !important;
}

.logotype {
  background: url(images/logo.png) no-repeat left top;
  background-size: 100% 100%;
  background-position: center;
  height: 60px;
  width: 102px;
}

.logotype-appetence {
  background: url(images/appetence.png) no-repeat left top;
  background-size: 100% 100%;
  height: 45px;
  margin-top:  5px;
  margin-bottom:  0;
}

.logo_container {
  background-color: var(--special_color);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left:  10px;
}

.logo_container .pubtrack-name, .pubtrack-divider {
  font-weight: bold;
  font-size: 1.4rem;
  color:  #fff;
  display:  block;
}

.pubtrack-divider {
  padding: 0 20px;
}

.affiliations_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affiliations_form input {
  min-width: 300px;
}

.affiliations_form .md-form {
  margin: 8px 0;
}

.container {
  max-width: 1280px !important;
} 
