.li_styles, .previous, .next  {
    cursor: pointer;
    border-radius:10px;
}

.li_styles a, .previous a, .next a  {
    padding:14px;
    cursor: pointer;
    border-radius:10px;
}

.li_styles a:hover, .previous a:hover, .next a:hover  {
    background-color: var(--special_color_dark);
    color:white !important;
}

.active a {
    padding:14px;
    background-color: var(--special_color_dark);
    border-radius:10px;
    color:white !important;
}

.hide {
    visibility: hidden;
}